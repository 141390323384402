import React, { useEffect,useState } from 'react'
// import axios from 'axios'
import { BASE_URL } from '../../constants';
import TimeCard from '../../components/TimeCard';
import * as Styled from './style';
import { Button } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function AdminTime() {
    const token =  localStorage.getItem('access_token')
    const [cards, setCards] = useState([]);
    const navigate = useNavigate();

    function handleLogOut() {
        navigate('/admin');
        localStorage.setItem('access_token', '');
    }

    useEffect(()=> {
        handleDownloadCards()
    },[])

    useEffect(()=> {
        if(cards.length !== 0) {
            handleDownloadEvents()
        }
    } ,[cards])

    function handleDownloadEvents () {
        axios.post(BASE_URL + 'auth/admin/getAllAvailableDatesPlanned' , {
            "token": token
        }).then(response => {
            const filteredEvents = response.data.events.filter(event => event.status === "planned") || []
            console.log(filteredEvents);
                cards.forEach(card => {
                    card.isAllDay = false;
                    card.eventId = '';
                    // if (filteredEvents[card.consultant_id]) {
                    //     filteredEvents[card.consultant_id].isAllDay = true;
                    // } else {
                    //     card.isAllDay = false;
                    // }
                    if(filteredEvents.length !== 0) {
                        filteredEvents.forEach(event => {
                            if (event.consultant_id === card.consultant_id) {
                                card.isAllDay = true;
                                card.eventId = event.eventId;
                            }
                        })
                    }
                },
            )
        })
    }

    useEffect(()=> {
        console.log(cards);
    } ,[cards])

    function handleDownloadCards() {
        axios.get(BASE_URL + 'schedules/getAllConsultants', {
            "token": token
        }).then(response => {
            const filteredConsultants = response.data.filter(data => data.consultant_id !== "51be60a5-df32-45d4-b3a7-9c47c81632af.af5a709a-f5ee-4ad6-9495-f2029820464a")
            filteredConsultants.forEach((consultant) => {
                if (consultant.name === "Rodrik David") {
                  consultant.name = 'Mediation Now';
                }
              });
            setCards(filteredConsultants)
        }).catch(e => {
            handleLogOut()
            console.error(e.message)
        })
    }

    return (
       <Styled.Container>
            <Styled.Navigation>
                <Button
                appearance="primary"
                onClick={() => navigate("/admin/events")}
                >
                Events
                </Button>
                <Button
                appearance="primary"
                onClick={() => navigate("/admin/consultants")}
                >
                Consultants
                </Button>
                <Button appearance="primary" onClick={() => handleLogOut()}>
                Log out
                </Button>
            </Styled.Navigation>
            {cards?.map((item,index) => {
                return  <TimeCard
                            key={index}
                            item={item}
                            handleDownloadCards={handleDownloadCards}
                        />
            })}
       </Styled.Container>
    )
}