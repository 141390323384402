import { styled } from "styled-components";

export const Container = styled.div`
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    position: relative;
`
export const Navigation = styled.div`
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
`

export const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
`