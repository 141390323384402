import { styled } from "styled-components";

export const Container = styled.div`
    border: 1px solid #575757;
    border-radius: 4px;
    padding: 30px 20px;
    width: 600px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    position: relative;
`
export const Details = styled.details`

`;

export const Summary = styled.summary`
    cursor: pointer;
`

export const DetailsContent = styled.div`
    margin: 20px 0 0 20px;
`

export const FieldContainer = styled.div`
    display: flex;
    gap: 5px;
`

export const FieldLabel = styled.label`
    width: 80px;
    text-transform: Capitalize;
`

export const FieldSubLabel = styled(FieldLabel)`
    width: 130px;
    text-transform: Capitalize;
`

export const FieldValue = styled.span`

`

export const FieldTitle = styled(FieldLabel)`
    width: auto;
`

export const FieldSubContainer = styled(FieldContainer)`
    margin-left: 20px;
`

export const Councel = styled.div`
    margin-left: 20px;
`