import React, {useEffect, useState} from 'react'
import * as Styled from './style'
import { Modal, Button, Loader, Input, SelectPicker, DatePicker } from 'rsuite';
import RemindIcon from '@rsuite/icons/legacy/Remind';
import './style.css'
import axios from 'axios';
import { BASE_URL, STATES, TIMESTEPS } from '../../constants';

export default function EventCard({...props}) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // console.log(props.item)
    // edit modal states
    const [form, setForm] = useState(props.item);
    const [details, setDetails] = useState(null);
    const [invalid, setInvalid] = useState([]);
    const [counsels, setCounsels] = useState(props.item.counsels);
    const [counselsIndeces, setCounselsIndeces] = useState(props.item.counsels);
    const [submitting, setSubmitting] = useState(false);
    const [openFormModal, setOpenFormModal] = useState(false);
    const [availSlots, setAvailSlots] = useState();
    const [slots,setSlots] = useState(getSlotsForSelect([props.item.startTime]));
    const [beforModalLoading, setBeforModalLoading] = useState(false);
    const [availSlotsFlag, setAvailSlotsFlag] = useState(true);
	const [maxDurationSlots, setMaxDurationSlots] = useState([TIMESTEPS.find(item => props.item?.duration === item?.value)]);
    const [limitingEventTime,setLimitingEventTime] = useState(null);

    useEffect(() => {
        console.log('props.item.startTime >>>>>', props.item.startTime)
        const startTime = new Date(props.item.startTime)
        const currentTime  = new Date()

        console.log('startTime >>>>>', startTime)
        console.log('currentTime >>>>>', currentTime)
        if(currentTime  > startTime) {
            setDetails(true)
        } else {
            setDetails(false)
        }

        console.log(details);

    }, [props.item.startTime, details])

    const onFormClose = () => {
        setOpenFormModal(false);
        setTimeout(() => {
            setForm({});
            setCounsels({});
            setCounselsIndeces([0, 1]);
            setSubmitting(false);
            setInvalid([]);
        }, 300);
    }

    const getConsultantSlotsByDate = (consultantId, date) => {
        axios.get(`${BASE_URL}getConsultantSlotsByDate?consultant_id=${consultantId}&date=${date}`)
            .then(resp => {
                setLimitingEventTime(resp?.data?.limitingEventTime)
                setAvailSlots(resp?.data?.slots ?? []);
                setSlots(getSlotsForSelect(resp?.data?.slots ?? []))
                setBeforModalLoading(false);
            }).catch(err => {
                console.error(err);
                setBeforModalLoading(false);
            });
    }

  
    function getSlotsForSelect (slots) {
        if (slots?.length > 0) {
            return slots.map(date => {
                let hours = new Date(date).getHours();
                let minutes = new Date(date).getMinutes();
                let ampm = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12;
                hours = hours ? hours : 12;
                minutes = minutes < 10 ? '0' + minutes : minutes;
                let strTime = hours + ':' + minutes + ' ' + ampm;
                return { label: strTime, value: date };
            })
        }

        return [];
    }



    const submitForm = (e) => {
        e.preventDefault();

        const councelArray = [];
        Object.entries(counsels).forEach(entry => {
            councelArray.push(entry[1]);
        });

        const formToSend = { ...form };
        formToSend.consultant_id = props.item.consultant_id;
		formToSend.caseInformation || (formToSend.caseInformation = {})
		formToSend?.caseInformation?.courtType || (formToSend.caseInformation.courtType = 'Voluntary');
        formToSend.counsels = councelArray;

		if (formToSend?.caseInformation?.courtType === 'Court Ordered') {
			formToSend?.caseInformation?.courtOrdered 
				? formToSend.caseInformation.courtOrdered = formToSend.caseInformation.courtOrdered.toISOString()
				: formToSend.caseInformation.courtOrdered = props.item.startTime
		}


        const validationResult = validateForm(formToSend);

        if (validationResult === true) {
            setSubmitting(true);
            console.log('formToSend',formToSend)
            axios.post(BASE_URL + 'calendar/updateEvent/' + props.item.eventId, formToSend).then(() => {
                setOpenFormModal(false);
                setSubmitting(false);
                props.handleDownloadCards()
            }).catch(err => {
                console.error(err);
                setSubmitting(false);
            })
        }
    }

    const calculateMaxTimeDuration = (selectedTime) => {
		if (!selectedTime) return null

		const lastSlotHour = Number.parseInt(availSlots[availSlots.length -1].slice(11,13))
		const lastSlotMinutes = Number.parseInt(availSlots[availSlots.length -1].slice(14,17))
		let index = availSlots.findIndex(item => item === selectedTime)
		const newArr = []

		let isSelectedTimeWithouGaps = true

		for (index; index < availSlots.length; index++) {
			const element = availSlots[index];
			const nextElement = availSlots[index + 1];
			if (!nextElement) {
				break
			}
			if ((Number.parseInt(element.slice(11,13)) !== Number.parseInt(nextElement.slice(11,13))) && (element.slice(13,17) === nextElement.slice(13,17))) {
				isSelectedTimeWithouGaps = false
			} else if (((Number.parseInt(element.slice(11,13)) + 1) < Number.parseInt(nextElement.slice(11,13))) && (element.slice(13,17) !== nextElement.slice(13,17))) {
				isSelectedTimeWithouGaps = false
			}
		}

		if (limitingEventTime && isSelectedTimeWithouGaps) {
			const limitingEventTimeHours = Number.parseInt(limitingEventTime.slice(11,13))
			let limitingEventTimeMinutes = Number.parseInt(limitingEventTime.slice(14,17))
			let pevot = lastSlotHour
			
			while(pevot < limitingEventTimeHours) {

				if (limitingEventTimeHours > lastSlotHour && limitingEventTimeMinutes === lastSlotMinutes) {
					newArr.push(1,1)
				} else if (limitingEventTimeMinutes !== lastSlotMinutes) {
					newArr.push(1)
					limitingEventTimeMinutes = lastSlotMinutes
				}
				pevot++
			}
		
		}

		let calcIndex = availSlots.findIndex(item => item === selectedTime)
		for (calcIndex; calcIndex < availSlots.length; calcIndex++) {
			const element = availSlots[calcIndex];
			const nextElement = availSlots[calcIndex + 1];
			
				if (!nextElement) {
					newArr.push(element)
					break;
				} else if (element.slice(11,13) === nextElement.slice(11,13)) {
					newArr.push(element)
				} else if (((Number.parseInt(element.slice(11,13)) + 1) === Number.parseInt(nextElement.slice(11,13))) && (element.slice(13,17) !== nextElement.slice(13,17))) {
					newArr.push(element)
				} else if (((Number.parseInt(element.slice(11,13)) + 1) === Number.parseInt(nextElement.slice(11,13))) && (element.slice(13,17) === nextElement.slice(13,17))) {
					newArr.push(element)
					break;
				} else {
					newArr.push(element)
					break;
				}
		}
		
		newArr.splice(0,3)
		setMaxDurationSlots(TIMESTEPS.filter((e,index)=> index < newArr.length ? e : null))
		setAvailSlotsFlag(e => !e)
	}

    const onEditFormOpen = async () => {
        setBeforModalLoading(true)
        getConsultantSlotsByDate(props.item.consultant_id, props.item.startTime)
        setOpenFormModal(true);
    }

    const validateForm = (form) => {
        const inv = [];

        (form.startTime !== null && form.startTime !== undefined) || inv.push({ index: 0, key: 'startTime', msg: 'This field cannot be empty' });
        (form.location !== null && form.location !== undefined) || inv.push({ index: 0, key: 'location', msg: 'This field cannot be empty' });
        (form.duration !== null && form.duration !== undefined) || inv.push({ index: 0, key: 'duration', msg: 'This field cannot be empty' });

        const locationOfMediation = form.locationOfMediation;
        
        const locationOfMediationValid = (locationOfMediation !== null && locationOfMediation !== undefined) || (form.location !== 'In Person (Other)' && form.location !== 'Hybrid (Zoom/In Person)');
        const locationNeeded = form.location === 'In Person (Other)' || form.location === 'Hybrid (Zoom/In Person)';

        if (locationNeeded) {
            (locationOfMediationValid && !isBlank(locationOfMediation.address)) || inv.push({ index: 0, key: 'locationAddress', msg: 'This field cannot be empty' });
            (locationOfMediationValid && !isBlank(locationOfMediation.city)) || inv.push({ index: 0, key: 'locationCity', msg: 'This field cannot be empty' });
            (locationOfMediationValid && !isBlank(locationOfMediation.state)) || inv.push({ index: 0, key: 'locationState', msg: 'This field cannot be empty' });
            (locationOfMediationValid && !isBlank(locationOfMediation.zip) && /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(locationOfMediation.zip)) || inv.push({ index: 0, key: 'locationZip', msg: 'This field has incorrect format' });
        }

        const caseInformation = form.caseInformation;
        !isBlank(caseInformation?.caseName) || inv.push({ index: 0, key: 'caseName', msg: 'This field cannot be empty' });
        !isBlank(caseInformation?.caseNumber) || inv.push({ index: 0, key: 'caseNumber', msg: 'This field cannot be empty' });
        !isBlank(caseInformation?.caseCourt) || inv.push({ index: 0, key: 'caseCourt', msg: 'This field cannot be empty' });

        const schedulerInformation = form.schedulerInformation;
        !isBlank(schedulerInformation?.schedulerName) || inv.push({ index: 0, key: 'schedulerName', msg: 'This field cannot be empty' });
        (!isBlank(schedulerInformation?.schedulerEmail) && validateEmail(schedulerInformation?.schedulerEmail)) || inv.push({ index: 0, key: 'schedulerEmail', msg: 'This field has incorrect format' });

        const counsels = form.counsels;
        if (counsels === null || counsels === undefined || counsels?.length === 0) {
            counsels.push({});
        }

        counsels?.forEach((councel, index) => {
            !isBlank(councel?.represent) || inv.push({ index, key: 'councelRepresent', msg: 'This field cannot be empty' });
            !isBlank(councel?.fullName) || inv.push({ index, key: 'councelFullName', msg: 'This field cannot be empty' });
            !isBlank(councel?.attorneyName) || inv.push({ index, key: 'councelAttorneyName', msg: 'This field cannot be empty' });
            (!councel?.attorneyEmail || validateEmail(councel?.attorneyEmail)) || inv.push({ index, key: 'councelAttorneyName', msg: 'This field cannot be empty' });
            !isBlank(councel.firm_or_organization) || inv.push({ index, key: 'councelFirmOrOrganization', msg: 'This field cannot be empty' });
            !isBlank(councel.address) || inv.push({ index, key: 'councelAddress', msg: 'This field cannot be empty' });
            (isBlank(councel.zip) || /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(councel.zip)) || inv.push({ index, key: 'councelZip', msg: 'This field has incorrect format' });
            !isBlank(councel.telephone) || inv.push({ index, key: 'councelTelephone', msg: 'This field cannot be empty' });
        });

        setInvalid(inv);
        return inv.length === 0;
    }

    const isBlank = (str) => {
        return (!str || /^\s*$/.test(str));
    }

    const validateEmail = (email) => {
        return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };


    const handleDelete = async () => {
        handleClose()
        // props.handleDelete()
        axios.delete(BASE_URL + 'calendar/' + props.item.eventId,{
            data: {
                consultant_id: props.item.consultant_id
            }
        }).then(response => {
                props.handleDownloadCards()
            }).catch(e => {
            console.error(e.message)
            })
    }

    const parseTime = (string) => {
        // "2023-08-03T21:00:00.390Z"
        const data = string.slice(0,10)
        let time = string.slice(11,16)
     
        if (parseInt(time.slice(0,2)) >12) {
            time = (parseInt(time.slice(0,2)) - 12) + time.slice(2,6) + ' PM'
        }  else if (parseInt(time.slice(0,2)) === 12) {
            time = (parseInt(time.slice(0,2))) + time.slice(2,6) + ' PM'
        } else {
            time = time + ' AM'
        }

        return time + ' ' + data
    }

    const removeCounselForm = (index) => {
        const newCouncels = {...counsels};
        delete newCouncels[index];
        setCounsels(newCouncels);
        setCounselsIndeces(counselsIndeces.filter(it => it !== index));
    }

    const addCounselForm = (item,index) => {

        return (
            <div key={index} >
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h5 style={{ color: 'rgb(7, 73, 115)' }}>Counsel:</h5>
                    {
                        index > 1 &&
                        <div style={{marginRight: '10px', cursor: 'pointer'}} onClick={() => removeCounselForm(index)}>
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0,0,256,256"><g fill="#074973" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none"><g transform="scale(9.84615,9.84615)"><path d="M13.5,3.1875c-5.69531,0 -10.3125,4.61719 -10.3125,10.3125c0,5.69531 4.61719,10.3125 10.3125,10.3125c5.69531,0 10.3125,-4.61719 10.3125,-10.3125c0,-5.69531 -4.61719,-10.3125 -10.3125,-10.3125zM19,15h-11v-3h11z"></path></g></g></svg>
                        </div>
                    }
                </div>
                
                <div style={{ display: 'flex', gap: '20px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '23%' }}>
                        <label>Represent <span style={{ color: 'red' }}>*</span></label>
                        <SelectPicker defaultValue={item.represent} disabled={submitting} searchable={false} data={[
                            { label: 'Plaintiff', value: 'Plaintiff' },
                            { label: 'Defendant', value: 'Defendant' },
                            { label: 'Other', value: 'Other' }
                        ]} onChange={e => setCounsels({ ...counsels, [index]: { ...(counsels[index] ?? {}), represent: e } })} />
                        <div className="form-error">{invalid.find(it => it.key === 'councelRepresent' && it.index === index)?.msg ?? ''}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '23%' }}>
                        <label>Full Name <span style={{ color: 'red' }}>*</span></label>
                        <Input defaultValue={item.fullName} disabled={submitting} placeholder="Plaintiff's/Defendant's name" onChange={e => setCounsels({ ...counsels, [index]: { ...(counsels[index] ?? {}), fullName: e } })} />
                        <div className="form-error">{invalid.find(it => it.key === 'councelFullName' && it.index === index)?.msg ?? ''}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '23%' }}>
                        <label>Attorneys Name <span style={{ color: 'red' }}>*</span></label>
                        <Input defaultValue={item.attorneyName} disabled={submitting} placeholder="Enter attorneys name" onChange={e => setCounsels({ ...counsels, [index]: { ...(counsels[index] ?? {}), attorneyName: e } })} />
                        <div className="form-error">{invalid.find(it => it.key === 'councelAttorneyName' && it.index === index)?.msg ?? ''}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '23%' }}>
                        <label>Attorneys E-mail </label>
                        <Input defaultValue={item.attorneyEmail} disabled={submitting} placeholder="Enter attorneys e-mail address" onChange={e => setCounsels({ ...counsels, [index]: { ...(counsels[index] ?? {}), attorneyEmail: e } })} />
                        <div className="form-error">{invalid.find(it => it.key === 'councelAttorneyEmail' && it.index === index)?.msg ?? ''}</div>
                    </div>
                </div>
                
                <div style={{ display: 'flex', gap: '20px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '23%' }}>
                        <label>Additional E-mails </label>
                        <Input defaultValue={item.additionalEmails} disabled={submitting} placeholder="Enter additional e-mail addresses" onChange={e => setCounsels({ ...counsels, [index]: { ...(counsels[index] ?? {}), additionalEmails: e } })} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '23%' }}>
                        <label>Firm/Organization <span style={{ color: 'red' }}>*</span></label>
                        <Input defaultValue={item.firm_or_organization} disabled={submitting} placeholder="Enter your firm/organization" onChange={e => setCounsels({ ...counsels, [index]: { ...(counsels[index] ?? {}), firm_or_organization: e } })} />
                        <div className="form-error">{invalid.find(it => it.key === 'councelFirmOrOrganization' && it.index === index)?.msg ?? ''}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '23%' }}>
                        <label>Address <span style={{ color: 'red' }}>*</span></label>
                        <Input defaultValue={item.address} disabled={submitting} placeholder="Enter address location" onChange={e => setCounsels({ ...counsels, [index]: { ...(counsels[index] ?? {}), address: e } })} />
                        <div className="form-error">{invalid.find(it => it.key === 'councelAddress' && it.index === index)?.msg ?? ''}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '23%' }}>
                        <label>City</label>
                        <Input defaultValue={item.city} disabled={submitting} placeholder="Enter city location" onChange={e => setCounsels({ ...counsels, [index]: { ...(counsels[index] ?? {}), city: e } })} />
                        <div className="form-error"></div>
                    </div>
                </div>
                
                <div style={{ display: 'flex', gap: '20px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '23%' }}>
                        <label>State</label>
                        <SelectPicker defaultValue={item.state} disabled={submitting} searchable={false} data={STATES} onChange={e => setCounsels({ ...counsels, [index]: { ...(counsels[index] ?? {}), state: e } })} />
                        <div className="form-error"></div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '23%' }}>
                        <label>Zip</label>
                        <Input defaultValue={item.zip} disabled={submitting} placeholder="Enter zip code" onChange={e => setCounsels({ ...counsels, [index]: { ...(counsels[index] ?? {}), zip: e } })} />
                        <div className="form-error">{invalid.find(it => it.key === 'councelZip' && it.index === index)?.msg ?? ''}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '23%' }}>
                        <label>Telephone <span style={{ color: 'red' }}>*</span></label>
                        <Input defaultValue={item.telephone} disabled={submitting} placeholder="Enter telephone number" onChange={e => setCounsels({ ...counsels, [index]: { ...(counsels[index] ?? {}), telephone: e } })} />
                        <div className="form-error">{invalid.find(it => it.key === 'councelTelephone' && it.index === index)?.msg ?? ''}</div>
                    </div>
                </div>

            </div>
        )
    }


  return (
    <Styled.Container className='event-card'>
        { openFormModal && 
            <Modal backdrop={'static'} overflow={true} open={openFormModal} onClose={() => onFormClose()} size='lg'>
            <Modal.Header>
                <Modal.Title>
                    <div>
                        <h3 style={{ 'margin': '20px 10px' }}>You Are Changing Scheduling Mediation With</h3>
                    </div>
                    <div style={{ display: 'flex', gap: '30px', 'margin': '20px 10px' }}>
                        <div className="modal-subtitle">
                            <div className="icon">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="34px" viewBox="0,0,256,256"><g fill="#2acf2a" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none"><g transform="scale(2,2)"><path d="M64,37c-12.70255,0 -23,10.29745 -23,23c0,12.70255 10.29745,23 23,23c12.70255,0 23,-10.29745 23,-23c0,-12.70255 -10.29745,-23 -23,-23zM64,91c-15,0 -29.1,7.39922 -37.5,19.69922c-1.7,2.5 -2.5,5.40039 -2.5,8.40039c0,1.6 1.4,2.90039 3,2.90039h74c1.6,0 3,-1.30039 3,-2.90039c0.1,-3 -0.8,-6.00039 -2.5,-8.40039c-8.4,-12.3 -22.5,-19.69922 -37.5,-19.69922z"></path></g></g></svg>
                            </div>
                            <div className="modal-subtitle-text" style={{ color: '#2ACF2A', 'marginTop': 0 }}>
                                {props.item?.name}
                            </div>
                        </div>
                        <div className="modal-subtitle">
                            <div className="icon">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24px" viewBox="0,0,256,256"><g fill="#070373" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none"><g transform="scale(5.12,5.12)"><path d="M12,0c-1.09375,0 -2,0.90625 -2,2v2h-6c-1.16016,0 -2,0.83984 -2,2v7h46v-7c0,-1.16016 -0.83984,-2 -2,-2h-6v-2c0,-1.09375 -0.90625,-2 -2,-2h-2c-1.09375,0 -2,0.90625 -2,2v2h-18v-2c0,-1.09375 -0.90625,-2 -2,-2zM12,2h2v6h-2zM36,2h2v6h-2zM2,15v31c0,1.16016 0.83984,2 2,2h42c1.16016,0 2,-0.83984 2,-2v-31zM12,21h5v5h-5zM19,21h5v5h-5zM26,21h5v5h-5zM33,21h5v5h-5zM12,28h5v5h-5zM19,28h5v5h-5zM26,28h5v5h-5zM33,28h5v5h-5zM12,35h5v5h-5zM19,35h5v5h-5zM26,35h5v5h-5zM33,35h5v5h-5z"></path></g></g></svg>
                            </div>
                            <div className="modal-subtitle-text">
                                {parseTime(props.item?.startTime)}
                            </div>
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>  
               
                <form style={{ 'margin': '10px 10px', 'width': '100%' }}>
                    <div style={{ display: 'flex', gap: '20px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '23%' }}>
                            <label>Start time <span style={{ color: 'red' }}>*</span></label>
                            { beforModalLoading && <Loader size="md" />}
                            { !beforModalLoading && 
                                <SelectPicker
                                    disabled={submitting}
                                    searchable={false}
                                    defaultValue={slots}
                                    data={slots}
                                    onChange={(e) => {
                                        calculateMaxTimeDuration(e)
                                        setForm({
                                            ...form,
                                            startTime: e,
                                        })
                                    }}
                                />
                            }
                            <label style={{ 'fontSize': '11px', 'marginTop': '-6px'}}>Duration - 2 hours</label>
                            <div className="form-error">{invalid.find(it => it.key === 'startTime')?.msg ?? ''}</div>
                        </div>
                        <div style={{ display: 'flex',flexDirection: 'column',gap: '10px',width: '23%',}}>
                            <label>Duration <span style={{ color: 'red' }}>*</span></label>
							    {!availSlotsFlag &&
											<SelectPicker
                                                disabled={submitting}
                                                searchable={false}
                                                defaultValue={props.item?.duration}
												cleanable={false}
												data={maxDurationSlots}
												onChange={(e) =>
													setForm({
														...form,
														duration: e,
													})
												}
											/>
										}
										{availSlotsFlag &&
											<SelectPicker
												disabled={submitting}
												searchable={false}
												defaultValue={props.item?.duration}
												cleanable={false}
												data={maxDurationSlots}
												onChange={(e) =>
													setForm({
														...form,
														duration: e,
													})
												}
											/>
										}
										<div className='form-error'>
											{invalid.find(
												(it) => it.key === 'duration'
											)?.msg ?? ''}
										</div>
									</div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '23%' }}>
                            <label>Location <span style={{ color: 'red' }}>*</span></label>
                            <SelectPicker defaultValue={props.item?.location} disabled={submitting} searchable={false} data={[
                                { label: 'Remote', value: 'Remote' },
                                { label: 'In Person (Sugarloaf ADR Offices)', value: 'In Person (Sugarloaf ADR Offices)' },
                                { label: 'In Person (Other)', value: 'In Person (Other)' },
                                { label: 'Hybrid (Zoom/In Person)', value: 'Hybrid (Zoom/In Person)' },
                            ]} onChange={e => setForm({ ...form, location: e })} />
                            <div className="form-error">{invalid.find(it => it.key === 'location')?.msg ?? ''}</div>
                        </div>
                    </div>
                    {
                        (form.location === 'In Person (Other)' || form.location === 'Hybrid (Zoom/In Person)') &&
                        <>
                             
                            <h5 style={{ color: 'rgb(7, 73, 115)' }}>Location of Mediation:</h5>
                            
                            <div style={{ display: 'flex', gap: '20px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '23%' }}>
                                    <label>Address <span style={{ color: 'red' }}>*</span></label>
                                    <Input defaultValue={props.item?.locationOfMediation?.address} disabled={submitting} placeholder="Enter address location" onChange={e => setForm({ ...form, locationOfMediation: { ...form?.locationOfMediation ?? {}, address: e } })} />
                                    <div className="form-error">{invalid.find(it => it.key === 'locationAddress')?.msg ?? ''}</div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '23%' }}>
                                    <label>City <span style={{ color: 'red' }}>*</span></label>
                                    <Input defaultValue={props.item?.locationOfMediation?.city} disabled={submitting} placeholder="Enter city location" onChange={e => setForm({ ...form, locationOfMediation: { ...form?.locationOfMediation ?? {}, city: e } })} />
                                    <div className="form-error">{invalid.find(it => it.key === 'locationCity')?.msg ?? ''}</div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '23%' }}>
                                    <label>State <span style={{ color: 'red' }}>*</span></label>
                                    <SelectPicker defaultValue={props.item?.locationOfMediation?.state} disabled={submitting} searchable={false} data={STATES} onChange={e => setForm({ ...form, locationOfMediation: { ...form?.locationOfMediation ?? {}, state: e } })} />
                                    <div className="form-error">{invalid.find(it => it.key === 'locationState')?.msg ?? ''}</div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '23%' }}>
                                    <label>Zip <span style={{ color: 'red' }}>*</span></label>
                                    <Input disabled={submitting} defaultValue={props.item?.locationOfMediation?.zip} placeholder="Enter zip code" onChange={e => setForm({ ...form, locationOfMediation: { ...form?.locationOfMediation ?? {}, zip: e } })} />
                                    <div className="form-error">{invalid.find(it => it.key === 'locationZip')?.msg ?? ''}</div>
                                </div>
                            </div>
                        
                        </>
                    }

                    
                    <h5 style={{ color: 'rgb(7, 73, 115)' }}>Case Information:</h5>
                    
                    <div style={{ display: 'flex', gap: '20px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '23%' }}>
                            <label>Case Name <span style={{ color: 'red' }}>*</span></label>
                            <Input disabled={submitting} defaultValue={props.item?.caseInformation?.caseName} placeholder="Enter case name" onChange={e => setForm({ ...form, caseInformation: { ...form?.caseInformation ?? {}, caseName: e } })} />
                            <div className="form-error">{invalid.find(it => it.key === 'caseName')?.msg ?? ''}</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '23%' }}>
                            <label>Case Number <span style={{ color: 'red' }}>*</span></label>
                            <Input disabled={submitting} defaultValue={props.item?.caseInformation?.caseNumber} placeholder="Enter case number" onChange={e => setForm({ ...form, caseInformation: { ...form?.caseInformation ?? {}, caseNumber: e } })} />
                            <div className="form-error">{invalid.find(it => it.key === 'caseNumber')?.msg ?? ''}</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '23%' }}>
                            <label>Case Court <span style={{ color: 'red' }}>*</span></label>
                            <Input disabled={submitting} defaultValue={props.item?.caseInformation?.caseCourt} placeholder="Enter case court" onChange={e => setForm({ ...form, caseInformation: { ...form?.caseInformation ?? {}, caseCourt: e } })} />
                            <div className="form-error">{invalid.find(it => it.key === 'caseCourt')?.msg ?? ''}</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '23%' }}>
                            <label>Court Type <span style={{ color: 'red' }}>*</span></label>
                            <SelectPicker defaultValue="Voluntary" cleanable={false} disabled={submitting} searchable={false} data={[
                                { label: 'Voluntary', value: 'Voluntary' },
                                { label: 'Court Ordered', value: 'Court Ordered' }
                            ]} onChange={e => setForm({ ...form, caseInformation: { ...form?.caseInformation ?? {}, courtType: e } })} />
                            <div className="form-error"></div>
                        </div>
                    </div>

                    { form?.caseInformation?.courtType === 'Court Ordered'  && 
									<div style={{ display: 'flex', gap: '20px' }}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											gap: '10px',
											width: '23%',
										}}
									>
										<label>
										Court Ordered{' '}
											<span style={{ color: 'red' }}>
												*
											</span>
										</label>
										<DatePicker
											disabled={submitting}
											defaultValue={new Date(props.item?.caseInformation?.courtOrdered)}
											placeholder='Choose data'
											onChange={(e) =>
												setForm({
													...form,
													caseInformation: {
														...(form?.caseInformation ??
															{}),
														courtOrdered: e,
													},
												})
											}
										/>
										<div className='form-error'>
											{invalid.find(
												(it) =>
													it.key === 'courtOrdered'
											)?.msg ?? ''}
										</div>
									</div>
								</div>
								}
                    
                    
                    <h5 style={{ color: 'rgb(7, 73, 115)' }}>Scheduler Information:</h5>
                    
                    <div style={{ display: 'flex', gap: '20px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '23%' }}>
                            <label>Name <span style={{ color: 'red' }}>*</span></label>
                            <Input defaultValue={props.item?.schedulerInformation?.schedulerName} disabled={submitting} placeholder="Enter scheduler name" onChange={e => setForm({ ...form, schedulerInformation: { ...form?.schedulerInformation ?? {}, schedulerName: e } })} />
                            <div className="form-error">{invalid.find(it => it.key === 'schedulerName')?.msg ?? ''}</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '23%' }}>
                            <label>Email <span style={{ color: 'red' }}>*</span></label>
                            <Input defaultValue={props.item?.schedulerInformation?.schedulerEmail}  disabled={submitting} placeholder="Enter scheduler email" onChange={e => setForm({ ...form, schedulerInformation: { ...form?.schedulerInformation ?? {}, schedulerEmail: e } })} />
                            <div className="form-error">{invalid.find(it => it.key === 'schedulerEmail')?.msg ?? ''}</div>
                        </div>
                    </div>

                    {
                        counselsIndeces.map((item,index) => {
                            return addCounselForm(item,index)
                        })
                    }
                    
                    <h5 style={{ color: 'rgb(7, 73, 115)' }}>Add Additional Counsel:</h5>
                    
                    <button
                        onClick={e => { e.preventDefault(); setCounselsIndeces([...counselsIndeces, counselsIndeces.length]); setCounsels({...counsels, [counselsIndeces.length]: {}}) }}
                        style={{ width: '100px', height: '100px', background: '#E6E6E6', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0,0,256,256">
                            <g fill="#2acf2a" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none"><g transform="scale(5.12,5.12)"><path d="M25,2c-12.683,0 -23,10.317 -23,23c0,12.683 10.317,23 23,23c12.683,0 23,-10.317 23,-23c0,-12.683 -10.317,-23 -23,-23zM37,26h-11v11h-2v-11h-11v-2h11v-11h2v11h11z"></path></g></g>
                        </svg>
                    </button>
                    
                    <h5 style={{ color: 'rgb(7, 73, 115)' }}>Additional Information:</h5>
                    
                    <textarea defaultValue={props.item?.additionalInformation} disabled={submitting} maxLength={2000} onChange={e => setForm({ ...form, additionalInformation: e.target.value })} style={{ width: '100%', padding: '10px', height: '150px', border: '#E6E6E6 1px solid' }}>
                    </textarea>
                </form>
            </Modal.Body>
            <Modal.Footer>
                {
                    <div style={{ display: 'flex', paddingTop: '30px' }}>
                        {
                            submitting || <Button appearance="primary" onClick={e => submitForm(e)}>SUBMIT</Button>
                        }
                        {
                            submitting && <Loader size="md" />
                        }

                    </div>
                }
            </Modal.Footer>
        </Modal>
        }

        <Modal backdrop="static" role="alertdialog" open={open} onClose={handleClose} style={{minHeight: 'auto'}}>
            <Modal.Body>
            <RemindIcon style={{ color: 'red', fontSize: 24 }} />
             Are you sure you want to delete this event?
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={handleDelete} appearance="primary">
                Yes
            </Button>
            <Button onClick={handleClose} appearance="subtle">
                No
            </Button>
            </Modal.Footer>
        </Modal>
        <Styled.FieldContainer>
            <Styled.FieldLabel>Start Time:</Styled.FieldLabel>
            <Styled.FieldValue>{parseTime(props.item.startTime)}</Styled.FieldValue>
        </Styled.FieldContainer>
        <Styled.FieldContainer>
            <Styled.FieldLabel>Name:</Styled.FieldLabel>
            <Styled.FieldValue>{props.item.name}</Styled.FieldValue>
        </Styled.FieldContainer>
        {
            details ? (
                <Styled.Details>
                    <Styled.Summary>More Information</Styled.Summary>
            <Styled.DetailsContent>
                    {props.item.duration && 
            <Styled.FieldContainer>
                <Styled.FieldLabel>Duration:</Styled.FieldLabel>
                <Styled.FieldValue>{props.item.duration}</Styled.FieldValue>
            </Styled.FieldContainer>
        }       
        <Styled.FieldContainer>
            <Styled.FieldLabel>location:</Styled.FieldLabel>

            <Styled.FieldValue>{props.item.location}</Styled.FieldValue>

        </Styled.FieldContainer>

        <Styled.FieldTitle>Case information</Styled.FieldTitle>
            <Styled.FieldSubContainer>
                <Styled.FieldSubLabel>name:</Styled.FieldSubLabel>
                <Styled.FieldValue>{props.item.caseInformation.caseName}</Styled.FieldValue>
            </Styled.FieldSubContainer>
            <Styled.FieldSubContainer>
                <Styled.FieldSubLabel>number:</Styled.FieldSubLabel>
                <Styled.FieldValue>{props.item.caseInformation.caseNumber}</Styled.FieldValue>
            </Styled.FieldSubContainer>
            <Styled.FieldSubContainer>
                <Styled.FieldSubLabel>court:</Styled.FieldSubLabel>
                <Styled.FieldValue>{props.item.caseInformation.caseCourt}</Styled.FieldValue>
            </Styled.FieldSubContainer>
            <Styled.FieldSubContainer>
                <Styled.FieldSubLabel>court type:</Styled.FieldSubLabel>
                <Styled.FieldValue>{props.item.caseInformation.courtType}</Styled.FieldValue>
            </Styled.FieldSubContainer>
            {props.item.caseInformation.courtOrdered &&
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>court ordered:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{ props.item.caseInformation.courtOrdered.slice(0,10)}</Styled.FieldValue>
                </Styled.FieldSubContainer>
            } 

        <Styled.Details>
            <Styled.Summary>Details</Styled.Summary>
            <Styled.DetailsContent>
            {props.item.locationOfMediation && <>
                <Styled.FieldTitle>location of mediation</Styled.FieldTitle>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>address:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{props.item.locationOfMediation.address}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>city:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{props.item.locationOfMediation.city}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>state:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{props.item.locationOfMediation.state}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>zip:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{props.item.locationOfMediation.zip}</Styled.FieldValue>
                </Styled.FieldSubContainer></>
            }

            <Styled.FieldTitle>Scheduler Information</Styled.FieldTitle>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>name:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{props.item.schedulerInformation.schedulerName}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>email:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{props.item.schedulerInformation.schedulerEmail}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                   

            <Styled.FieldTitle style={{fontWeight: '600'}}>Counsels:</Styled.FieldTitle>
            

            {props.item.counsels.map((counsel,inx) => {
                return (<Styled.Councel>
                <Styled.FieldTitle>Counsel {inx + 1}</Styled.FieldTitle>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>represent:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{counsel.represent}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>Full name:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{counsel.fullName}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>email:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{counsel.email}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>Attorney Name:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{counsel.attorneyName}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>Attorney Email:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{counsel.attorneyEmail}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>Additional Emails:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{counsel.additionalEmails}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>firm or organization:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{counsel.firm_or_organization}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>address:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{counsel.address}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>city:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{counsel.city}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>state:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{counsel.state}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>zip:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{counsel.zip}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>telephone:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{counsel.telephone}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                
                </Styled.Councel>)
            })}
            <Styled.FieldContainer>
                <Styled.FieldLabel>Additional info:</Styled.FieldLabel>
                <Styled.FieldValue>{props.item?.additionalInformation}</Styled.FieldValue>
            </Styled.FieldContainer>

            </Styled.DetailsContent>
        </Styled.Details>
        </Styled.DetailsContent>
                </Styled.Details>
            ) : (
                <>
                    {props.item.duration && 
            <Styled.FieldContainer>
                <Styled.FieldLabel>Duration:</Styled.FieldLabel>
                <Styled.FieldValue>{props.item.duration}</Styled.FieldValue>
            </Styled.FieldContainer>
        }       
        <Styled.FieldContainer>
            <Styled.FieldLabel>location:</Styled.FieldLabel>

            <Styled.FieldValue>{props.item.location}</Styled.FieldValue>

        </Styled.FieldContainer>

        <Styled.FieldTitle>Case information</Styled.FieldTitle>
            <Styled.FieldSubContainer>
                <Styled.FieldSubLabel>name:</Styled.FieldSubLabel>
                <Styled.FieldValue>{props.item.caseInformation.caseName}</Styled.FieldValue>
            </Styled.FieldSubContainer>
            <Styled.FieldSubContainer>
                <Styled.FieldSubLabel>number:</Styled.FieldSubLabel>
                <Styled.FieldValue>{props.item.caseInformation.caseNumber}</Styled.FieldValue>
            </Styled.FieldSubContainer>
            <Styled.FieldSubContainer>
                <Styled.FieldSubLabel>court:</Styled.FieldSubLabel>
                <Styled.FieldValue>{props.item.caseInformation.caseCourt}</Styled.FieldValue>
            </Styled.FieldSubContainer>
            <Styled.FieldSubContainer>
                <Styled.FieldSubLabel>court type:</Styled.FieldSubLabel>
                <Styled.FieldValue>{props.item.caseInformation.courtType}</Styled.FieldValue>
            </Styled.FieldSubContainer>
            {props.item.caseInformation.courtOrdered &&
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>court ordered:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{ props.item.caseInformation.courtOrdered.slice(0,10)}</Styled.FieldValue>
                </Styled.FieldSubContainer>
            } 

        <Styled.Details>
            <Styled.Summary>Details</Styled.Summary>
            <Styled.DetailsContent>
            {props.item.locationOfMediation && <>
                <Styled.FieldTitle>location of mediation</Styled.FieldTitle>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>address:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{props.item.locationOfMediation.address}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>city:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{props.item.locationOfMediation.city}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>state:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{props.item.locationOfMediation.state}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>zip:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{props.item.locationOfMediation.zip}</Styled.FieldValue>
                </Styled.FieldSubContainer></>
            }

            <Styled.FieldTitle>Scheduler Information</Styled.FieldTitle>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>name:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{props.item.schedulerInformation.schedulerName}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>email:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{props.item.schedulerInformation.schedulerEmail}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                   

            <Styled.FieldTitle style={{fontWeight: '600'}}>Counsels:</Styled.FieldTitle>
            

            {props.item.counsels.map((counsel,inx) => {
                return (<Styled.Councel>
                <Styled.FieldTitle>Counsel {inx + 1}</Styled.FieldTitle>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>represent:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{counsel.represent}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>Full name:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{counsel.fullName}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>email:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{counsel.email}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>Attorney Name:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{counsel.attorneyName}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>Attorney Email:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{counsel.attorneyEmail}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>Additional Emails:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{counsel.additionalEmails}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>firm or organization:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{counsel.firm_or_organization}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>address:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{counsel.address}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>city:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{counsel.city}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>state:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{counsel.state}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>zip:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{counsel.zip}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                <Styled.FieldSubContainer>
                    <Styled.FieldSubLabel>telephone:</Styled.FieldSubLabel>
                    <Styled.FieldValue>{counsel.telephone}</Styled.FieldValue>
                </Styled.FieldSubContainer>
                
                </Styled.Councel>)
            })}
            <Styled.FieldContainer>
                <Styled.FieldLabel>Additional info:</Styled.FieldLabel>
                <Styled.FieldValue>{props.item?.additionalInformation}</Styled.FieldValue>
            </Styled.FieldContainer>

            </Styled.DetailsContent>
        </Styled.Details>
                </>
            )
        }
        <Button appearance="primary" onClick={() => onEditFormOpen()}>Edit</Button>
        <Button appearance="primary" onClick={() => handleOpen()}>Delete</Button>

    </Styled.Container>
  )
}
