export const BASE_URL = process.env.REACT_APP_BASE_URL
export const MONTHS = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

export const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const TIMESTEPS = [
    {
        label: '2:00 hour',
        value: '02:00',
    },
    {
        label: '2:30 hour',
        value: '02:30',
    },
    {
        label: '3:00 hour',
        value: '03:00',
    },
    {
        label: '3:30 hour',
        value: '03:30',
    },
    {
        label: '4:00 hour',
        value: '04:00',
    },
    {
        label: '4:30 hour',
        value: '04:30',
    },
    {
        label: '5:00 hour',
        value: '05:00',
    },
    {
        label: '5:30 hour',
        value: '05:30',
    },
    {
        label: '6:00 hour',
        value: '06:00',
    },
    {
        label: '6:30 hour',
        value: '06:30',
    },
    {
        label: '7:00 hour',
        value: '07:00',
    },
    {
        label: '7:30 hour',
        value: '07:30',
    },
    {
        label: '8:00 hour',
        value: '08:00',
    },
    {
        label: '8:30 hour',
        value: '08:30',
    },
    {
        label: '9:00 hour',
        value: '09:00',
    },
    {
        label: '9:30 hour',
        value: '09:30',
    },
    {
        label: '10:00 hour',
        value: '10:00',
    },
    {
        label: '10:30 hour',
        value: '10:30',
    },
    {
        label: '11:00 hour',
        value: '11:00',
    },
    {
        label: '11:30 hour',
        value: '12:30',
    },
    {
        label: '12:00 hour',
        value: '12:00',
    },
]

export const STATES = [
    {
        "label": "Alabama",
        "value": "Alabama"
    },
    {
        "label": "Alaska",
        "value": "Alaska"
    },
    {
        "label": "Arizona",
        "value": "Arizona"
    },
    {
        "label": "Arkansas",
        "value": "Arkansas"
    },
    {
        "label": "California",
        "value": "California"
    },
    {
        "label": "Colorado",
        "value": "Colorado"
    },
    {
        "label": "Connecticut",
        "value": "Connecticut"
    },
    {
        "label": "Delaware",
        "value": "Delaware"
    },
    {
        "label": "Florida",
        "value": "Florida"
    },
    {
        "label": "Georgia",
        "value": "Georgia"
    },
    {
        "label": "Hawaii",
        "value": "Hawaii"
    },
    {
        "label": "Idaho",
        "value": "Idaho"
    },
    {
        "label": "Illinois",
        "value": "Illinois"
    },
    {
        "label": "Indiana",
        "value": "Indiana"
    },
    {
        "label": "Iowa",
        "value": "Iowa"
    },
    {
        "label": "Kansas",
        "value": "Kansas"
    },
    {
        "label": "Kentucky",
        "value": "Kentucky"
    },
    {
        "label": "Louisiana",
        "value": "Louisiana"
    },
    {
        "label": "Maine",
        "value": "Maine"
    },
    {
        "label": "Maryland",
        "value": "Maryland"
    },
    {
        "label": "Massachusetts",
        "value": "Massachusetts"
    },
    {
        "label": "Michigan",
        "value": "Michigan"
    },
    {
        "label": "Minnesota",
        "value": "Minnesota"
    },
    {
        "label": "Mississippi",
        "value": "Mississippi"
    },
    {
        "label": "Missouri",
        "value": "Missouri"
    },
    {
        "label": "Montana",
        "value": "Montana"
    },
    {
        "label": "Nebraska",
        "value": "Nebraska"
    },
    {
        "label": "Nevada",
        "value": "Nevada"
    },
    {
        "label": "New Hampshire",
        "value": "New Hampshire"
    },
    {
        "label": "New Jersey",
        "value": "New Jersey"
    },
    {
        "label": "New Mexico",
        "value": "New Mexico"
    },
    {
        "label": "New York",
        "value": "New York"
    },
    {
        "label": "North Carolina",
        "value": "North Carolina"
    },
    {
        "label": "North Dakota",
        "value": "North Dakota"
    },
    {
        "label": "Ohio",
        "value": "Ohio"
    },
    {
        "label": "Oklahoma",
        "value": "Oklahoma"
    },
    {
        "label": "Oregon",
        "value": "Oregon"
    },
    {
        "label": "Pennsylvania",
        "value": "Pennsylvania"
    },
    {
        "label": "Rhode Island",
        "value": "Rhode Island"
    },
    {
        "label": "South Carolina",
        "value": "South Carolina"
    },
    {
        "label": "South Dakota",
        "value": "South Dakota"
    },
    {
        "label": "Tennessee",
        "value": "Tennessee"
    },
    {
        "label": "Texas",
        "value": "Texas"
    },
    {
        "label": "Utah",
        "value": "Utah"
    },
    {
        "label": "Vermont",
        "value": "Vermont"
    },
    {
        "label": "Virginia",
        "value": "Virginia"
    },
    {
        "label": "Washington",
        "value": "Washington"
    },
    {
        "label": "West Virginia",
        "value": "West Virginia"
    },
    {
        "label": "Wisconsin",
        "value": "Wisconsin"
    },
    {
        "label": "Wyoming",
        "value": "Wyoming"
    }
]