import React from 'react'
import * as Styled from './style';
import { Input, InputGroup } from 'rsuite';
import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';

export default function Field({...props}) {

const [visible, setVisible] = React.useState(false);

  const handleChange = () => {
    setVisible(!visible);
  };

  const passwordStyles = {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
  }

  if(props.type === 'password') {
    return (
        <Styled.Container>
            <label>{props.name} <span style={{ color: 'red' }}>*</span></label>
            <InputGroup inside >
                <Input
                  type={visible ? 'text' : 'password'}
                  placeholder={props.placeholder}
                  value={props.value}
                  onChange={(newValue) => props.setValue(newValue)}
                />
                <InputGroup.Button onClick={handleChange}>
                {visible ? <EyeIcon /> : <EyeSlashIcon />}
                </InputGroup.Button>
            </InputGroup>
        </Styled.Container>
    )}

  return (
    <Styled.Container>
        <label>{props.name} <span style={{ color: 'red' }}>*</span></label>
        <Input placeholder={props.placeholder} value={props.value} onChange={(newValue) => props.setValue(newValue)}/>
        {!props.isValid.flag && <div className="form-error">{props.isValid.message}</div>}
    </Styled.Container>
  )
}
