import React, { useState } from 'react'
import './style.css'

export default function Header() {
    const [isOpen, setIsOpen] = useState(false);
  return (
    <>
    <header className='header'>
        <div className='header-container'>
            <div className="logo">
                <img src="https://sugarloafadr.kozlen.com/wp-content/uploads/2023/07/cropped-cropped-FINAL-Sugarloaf-Color_Horz-1-1.png" alt="Sugarloaf ADR Horizontal Logo" width="200" />
            </div>
            <div className='menu-items-container'>
                <div className="menu-item">
                    <a href="https://sugarloafadr.com/#page-top">HOME</a>
                </div>
                <div className="menu-item active">
                    <a href='https://calendar.sugarloafadr.com/'>SCHEDULE A MEDIATION</a>
                </div>
                <div className="menu-item">
                    <a href="https://sugarloafadr.com/mediation-now/">MEDIATION NOW</a>
                </div>
                <div className="menu-item">
                    <a href="https://sugarloafadr.com/meet-the-neutrals/">MEET OUR NEUTRALS</a>
                </div>
                <div className="menu-item">
                    <a href="https://sugarloafadr.com/pricing/">PRICING</a>
                </div>
                <div className="menu-item">
                    <a href="https://sugarloafadr.com/about-us-2/">ABOUT US</a>
                </div>
            </div>
          
            <div className='mobile-container' onClick={() => setIsOpen(e => !e)}>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="bars" viewBox="0 0 1536 1896.0833">
                    <path d="M1536 1344v128q0 26-19 45t-45 19H64q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19H64q-26 0-45-19T0 960V832q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19H64q-26 0-45-19T0 448V320q0-26 19-45t45-19h1408q26 0 45 19t19 45z"></path>
                </svg>
            </div>

            
        </div>
    </header>
    <div className='header-gap'></div>
    <div className={isOpen ? 'menu-shadow active' : 'menu-shadow'} onClick={() => setIsOpen(false)}></div>
    <nav className={isOpen ? 'mobile-menu active' : 'mobile-menu'}>
        <div className="mobile-logo">
            <img src="https://sugarloafadr.kozlen.com/wp-content/uploads/2023/07/cropped-cropped-FINAL-Sugarloaf-Color_Horz-1-1.png" alt="Sugarloaf ADR Horizontal Logo" width="200" />
        </div>
        <ul className="menu-header-menu">
            <li className="menu-item-mobile "><a href="https://sugarloafadr.com/#page-top" aria-current="page">Home</a></li>
            <li className="menu-item-mobile active"><a href="https://calendar.sugarloafadr.com">Schedule a Mediation</a></li>
            <li className="menu-item-mobile "><a href="https://sugarloafadr.com/mediation-now/">Mediation Now</a></li>
            <li className="menu-item-mobile "><a href="https://sugarloafadr.com/meet-the-neutrals/">Meet our Neutrals</a></li>
            <li className="menu-item-mobile "><a href="https://sugarloafadr.com/pricing/">Pricing</a></li>
            <li className="menu-item-mobile "><a href="https://sugarloafadr.com/about-us-2/">About Us</a></li>
        </ul>
    </nav>
    </>
  )
}
