import React, {useState} from 'react'
import { Button } from 'rsuite';
import Field from '../../components/Field/Field';
import * as Styled from './style';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../constants';

export default function AdminLogin() {
    const [submitting, setSubmitting] = useState(false);
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate()

    const handleSetLogin = (value) => {
        setLogin(value)
    }

    const handlesetPassword = (value) => {
        setPassword(value)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(BASE_URL + 'auth/admin/signin', {
            "email": login,
            "password": password
        }).then(response => {
            if(response.data.accessToken) {
                localStorage.setItem('access_token',response.data.accessToken)
                navigate('./events')
            }
        }).catch(e => console.error(e.message))
    }

    
  return (
    <Styled.Container>  
        <Styled.Form onSubmit={e => handleSubmit(e)}>
            <Field
                name='Login'
                placeholder='Enter email'
                value={login}
                setValue={(value) => handleSetLogin(value)}
                isValid= {
                    {
                        flag: true,
                        message: 'Email is not valid!'
                    }
                }
            />
             <Field
                name='Password'
                placeholder='Enter password'
                type='password'
                value={password}
                setValue={(value) => handlesetPassword(value)}
                isValid= {
                    {
                        flag: true,
                        message: 'Login is not valid!'
                    }
                }
            />
            <Button type='submit' appearance="primary" >SUBMIT</Button>
        </Styled.Form>
    </Styled.Container>
  )
}
