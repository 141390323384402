import React, { useEffect, useState } from "react";
// import axios from 'axios'
import { BASE_URL } from "../../constants";
import EventCard from "../../components/EventCard";
import * as Styled from "./style";
import { Button } from "rsuite";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function AdminEvents() {
  const [cards, setCards] = useState([]);
  const navigate = useNavigate();

  function handleLogOut() {
    navigate("/admin");
    localStorage.setItem("access_token", "");
  }

  useEffect(() => {
    handleDownloadCards();
  }, []);

  useEffect(() => {
    console.log('cards >>>', cards)
  }, [cards]);

  
  async function handleCardsFilter(cards) {
    const today = new Date();

    const compareDates = cards.sort((a, b) => {
      const dateObjectA = new Date(a.startTime);
      const dateObjectB = new Date(b.startTime);

      if (dateObjectA < today) {
        return 1;
      } else if (dateObjectB < today) {
        return -1;
      } else {
        return 0;
      }
    })

    setCards(compareDates);
  }

  function handleDownloadCards() {
    const token = localStorage.getItem("access_token");
    axios
      .post(BASE_URL + "auth/admin/getAllAvailableDatesPlanned", {
        token: token,
      })
      .then((response) => {
        handleCardsFilter(response.data.events);
      })
      .catch((e) => {
        handleLogOut();
        console.error(e.message);
      });
  }

  return (
    <Styled.Container>
      <Styled.Navigation>
        <Button
          appearance="primary"
          onClick={() => navigate("/admin/schedule")}
        >
          Schedule
        </Button>
        <Button
          appearance="primary"
          onClick={() => navigate("/admin/consultants")}
        >
          Consultants
        </Button>
        <Button appearance="primary" onClick={() => handleLogOut()}>
          Log out
        </Button>
      </Styled.Navigation>
      {cards?.length > 0 ? cards.map((item) => {
        return (
          <EventCard
            key={item.eventId}
            item={item}
            handleDownloadCards={handleDownloadCards}
          />
        );
      }) : null}
    </Styled.Container>
  );
}
