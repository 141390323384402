import React from "react";
import update from "immutability-helper";
import { useCallback, useState, useEffect } from "react";
import * as Styled from "./style";
import { Button } from "rsuite";
import { useNavigate } from "react-router-dom";
import ConsultantCard from "../../components/ConsultantCard";
import axios from "axios";
import { BASE_URL } from "../../constants";
import toast, { Toaster } from 'react-hot-toast'
import './styles.css'

const style = {
  width: 400,
};

const AdminConsultants = () => {
  const [cards, setCards] = useState();
  const [invitePopup, setInvitePopup] = useState(false);
  const [reconnectPopup, setReconnectPopup] = useState(false);
  const navigate = useNavigate();
  let emailsArray = [];

  useEffect(() => {
    console.log("cards ====>>>> ", cards);
  }, [cards]);

  function handleLogOut() {
    navigate("/admin");
    localStorage.setItem("access_token", "");
  }

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios.get(BASE_URL + "getAllConsultants").then((response) => {
      const filteredConsultants = response?.data?.consultants.filter((consultant) => consultant.order !== undefined)
      filteredConsultants.forEach((consultant) => {
        if (consultant.name === "Rodrik David") {
          consultant.name = 'Mediation Now';
        }
      });
      const sortedCards = filteredConsultants.sort((a, b) => a.order - b.order);
      setCards(sortedCards);
    });
  }

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    console.log('dragIndex: ',dragIndex);
    console.log('hoverIndex: ',hoverIndex);
    setCards((prevCards) => {
      const draggedCard = prevCards[dragIndex];
      const hoveredCard = prevCards[hoverIndex];
  
      const newDraggedOrder = hoveredCard.order;
      const newHoveredOrder = draggedCard.order;
  
      const updatedCards = prevCards.map((card, index) => {
        if (index === dragIndex) {
          return { ...card, order: newDraggedOrder };
        }
        if (index === hoverIndex) {
          return { ...card, order: newHoveredOrder };
        }
        return card;
      });

      const sortedUpdatesCards = updatedCards.sort((a, b) => a.order - b.order)
  
      return sortedUpdatesCards;
    });
  }, []);

  function handleAddNew () {
    window.location.href = `${BASE_URL}auth/signin`
  }

  function handleSave () {
    axios.post(BASE_URL + 'updateAllConsultantsOrder', {
      consultants: cards
    }).then(res => {
      console.log(res);
      console.log('complete');
      window.location.reload()
    }).catch(err =>{
      console.error(err);
    })
  }

  function handleReconnect (count) {
    axios.post(BASE_URL + `reinvite?count=${count}`, {emails: count === 'all' ? [] : emailsArray}).then(() => {
      emailsArray = []
      toast.success('Emails send was successful')
    }).catch(err =>{
      toast.error('Error')
    });
  }

  const handleShare = () => {
    axios.post(BASE_URL + 'invite', {emails : emailsArray}).then(() => {
      emailsArray = []
      setInvitePopup(false)
      toast.success('Emails send was successful')
    }).catch((err) => {
      toast.error('Error')
    })
	};
  
  const handleEmail = e => {
    const inputText = e.target.value;
    const emails = inputText.split(',').map(email => email.trim());
    emailsArray = emails.filter(email => email !== '');
  };
  
  const renderCard = useCallback((card, index) => {
    return (
      <ConsultantCard
        key={card.consultant_id}
        index={index}
        id={card.consultant_id}
        text={`${card.order}. ${card.name}`}
        moveCard={moveCard}
      />
    );
  }, []);

  return (
    <Styled.Container>
      <Styled.Navigation>
      <Toaster position="top-right"/>
        <Button
          appearance="primary"
          onClick={() => navigate("/admin/schedule")}
        >
          Schedule
        </Button>
        <Button
          appearance="primary"
          onClick={() => navigate("/admin/consultants")}
        >
          Consultants
        </Button>
        <Button appearance="primary" onClick={() => handleLogOut()}>
          Log out
        </Button>
      </Styled.Navigation>
      <div style={style}>
        {cards && cards.map((card, i) => renderCard(card, i))}
        <Styled.ButtonContainer>
        <Button
          appearance="primary"
          onClick={() => handleAddNew()}
        >
          Add New
        </Button>
        <Button
          appearance="primary"
          onClick={() => setInvitePopup(true)}
        >
          Invite
        </Button>
        <Button
          appearance="primary"
          onClick={() => setReconnectPopup(true)}
        >
          Reconnect
        </Button>
        <Button
          appearance="primary"
          onClick={() => handleSave()}
        >
          Save
        </Button>
        </Styled.ButtonContainer>
      </div>
      {invitePopup && (
        <div className='popup'>
          <div className="input-block">
            <h5 className="popup-header">Invite new Consultants</h5>
            <p className="instruction">Please, enter the comma-separated list of emails</p>
            <p className="example">example1@email.com, example2@email.com, ...</p>
            <textarea
              type="text"
              className='emails-textarea'
              placeholder={`example1@gmail.com, example2@gmail.com`}
              onChange={handleEmail}
            />
            <div className="button-block">
              <Button className="share-button" appearance="primary" onClick={handleShare}>
                Share
              </Button>
              <Button className="close-button" appearance="primary" onClick={() => {
                emailsArray = []
                setInvitePopup(false)
                }}>
                Close
              </Button>
            </div>
          </div>
			  </div>
      )}
      {reconnectPopup && (
        <div className='popup'>
          <div className="input-block">
            <h5 className="popup-header">Reconnect Consultants</h5>
            <p className="instruction">Please, enter the comma-separated list of emails</p>
            <p className="example">example1@email.com, example2@email.com, ...</p>
            <textarea
              type="text"
              className='emails-textarea'
              placeholder={`example1@gmail.com, example2@gmail.com`}
              onChange={handleEmail}
            />
            <div className="button-block">
              <Button className="share-button" appearance="primary" onClick={() => handleReconnect('list')}>
                Reconnect
              </Button>
              <Button className="share-button" appearance="primary" onClick={() => handleReconnect('all')}>
                Reconnect all
              </Button>
              <Button className="close-button" appearance="primary" onClick={() => {
                emailsArray = []
                setReconnectPopup(false)
                }}>
                Close
              </Button>
            </div>
          </div>
			  </div>
      )}
    </Styled.Container>
  );
};

export default AdminConsultants;
