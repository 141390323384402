import './App.css';
import React from 'react'
import CalendarPage from './pages/CalendarPage';
import AdminLogin from './pages/AdminLogin';
import AdminEvents from './pages/AdminEvents';
import AdminConsultants from './pages/AdminConsultants';
import Header from './components/Header';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AdminTime from './pages/AdminTime';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { hotjar } from 'react-hotjar';

function App() {
  React.useEffect(() => {
    hotjar.initialize(3698051, 6);
  }, []);

	return (
		<BrowserRouter>
			<DndProvider backend={HTML5Backend}>
				<Header />
				<Routes>
					<Route path='/' element={<CalendarPage />} />
					<Route path='/admin' element={<AdminLogin />} />
					<Route path='/admin/events' element={<AdminEvents />} />
					<Route path='/admin/schedule' element={<AdminTime />} />
					<Route path='/admin/consultants' element={<AdminConsultants />} />
				</Routes>
			</DndProvider>
		</BrowserRouter>
	);
}

export default App;
