import React, { useState, useEffect } from 'react'
import { Modal, Button, Checkbox, SelectPicker } from 'rsuite';
import * as Styled from './style';
import { DAYS, BASE_URL } from '../../constants';
import axios from 'axios';

const selectTimes = [
    { label: '6:00AM', value: '06:00'},
    { label: '6:30AM', value: '06:30' },
    { label: '7:00AM', value: '07:00' },
    { label: '7:30AM', value: '07:30' },
    { label: '8:00AM', value: '08:00' },
    { label: '8:30AM', value: '08:30' },
    { label: '9:00AM', value: '09:00' },
    { label: '9:30AM', value: '09:30' },
    { label: '10:00AM', value: '10:00' },
    { label: '10:30AM', value: '10:30' },
    { label: '11:00AM', value: '11:00' },
    { label: '11:30AM', value: '11:30' },
    { label: '12:00PM', value: '12:00' },
    { label: '12:30PM', value: '12:30' },
    { label: '1:00PM', value: '13:00' },
    { label: '1:30PM', value: '13:30' },
    { label: '2:00PM', value: '14:00' },
    { label: '2:30PM', value: '14:30' },
    { label: '3:00PM', value: '15:00' },
    { label: '3:30PM', value: '15:30' },
    { label: '4:00PM', value: '16:00' },
    { label: '4:30PM', value: '16:30' },
    { label: '5:00PM', value: '17:00' },
    { label: '5:30PM', value: '17:30' },
    { label: '6:00PM', value: '18:00' },
    { label: '6:30PM', value: '18:30' },
    { label: '7:00PM', value: '19:00' },
    { label: '7:30PM', value: '19:30' },
    { label: '8:00PM', value: '20:00' },
    { label: '8:30PM', value: '20:30' },
    { label: '9:00PM', value: '21:00' },
    { label: '9:30PM', value: '21:30' },
    { label: '10:00PM', value: '22:00' },
    { label: '10:30PM', value: '22:30' },
    { label: '11:00PM', value: '23:00' },
    { label: '11:30PM', value: '23:30' },
]

const mediationsPerDay = [
    { label: 'Max Mediations', value: 0 },
    { label: '1 Mediation', value: 1 },
    { label: '2 Mediations', value: 2 },
]

export default function TimeCard({ ...props }) {
    const [open, setOnOpen] = useState(false);
    const [renderFlag,setRenderFlag] = useState(true);
    const [isCalendarsOpen, setIsCalendarsOpen] = useState(false);
    const [calendars, setCalendars] = useState([])
    const [chosenCalendar, setChosenCalendar] = useState(null)
    const [isAllDay, setIsAllDay] = useState()
    const [form, setForm] = useState({
        schedule: props.item.schedule,
        mediationsPerDay: props.item.mediationsPerDay
    });

    const [sunTimes,setSunTimes] = useState(selectTimes);
    const [monTimes,setMonTimes] = useState(selectTimes);
    const [tueTimes,setTueTimes] = useState(selectTimes);
    const [wedTimes,setWedTimes] = useState(selectTimes);
    const [thuTimes,setThuTimes] = useState(selectTimes);
    const [friTimes,setFriTimes] = useState(selectTimes);
    const [satTimes,setSatTimes] = useState(selectTimes);

    const [invalidFields,setInvalidFields] = useState([0,0,0,0,0,0,0]);

    const handleOpen = () => setOnOpen(true);
    const handleClose = () => setOnOpen(false);

    useEffect(() => {
        handleConsultantCalendarsLoad()
    },[])

    useEffect(() => {
        setIsAllDay(props.item.isAllDay)
    },[props.item.isAllDay]) 

    useEffect(() => {
        console.log(isAllDay)
    },[isAllDay]) 

    const handleConfirm = () => {
        const token =  localStorage.getItem('access_token')
        const arr = [0,0,0,0,0,0,0]
        Object.keys(form.schedule).forEach((key,index) => {
            const start = Number.parseFloat(`${form.schedule[key]?.start?.slice(0,2)}.${form.schedule[key]?.start?.slice(3,5)}`) 
            const end = Number.parseFloat(`${form.schedule[key]?.end?.slice(0,2)}.${form.schedule[key]?.end?.slice(3,5)}`) 
            if (start !== NaN && end !== NaN) {
                if ((start > end) && form.schedule[key].available) {
                    arr.splice(index,1,1);
                } 
            }
        })
        if (!arr.every(elem => elem === 0)) {
            setInvalidFields(arr)
        } else {
            axios.post(BASE_URL + 'schedules/consultant/' + props.item.consultant_id, {
                "token": token,
                schedule: form.schedule,
                mediationsPerDay: form.mediationsPerDay
            }).then(response => {
                props.handleDownloadCards()
                handleClose()
            }).catch(e => {
                console.error(e.message)
            })
        }
    }

    function handleCLoseCalendarModal() {
        setIsCalendarsOpen(false)
    }

    function handleConsultantCalendarsLoad () {
        axios.get(`${BASE_URL}getConsultantCalendars?consultant_id=${props.item.consultant_id}`)
            .then(resp => {
                console.log(resp?.data)
                setChosenCalendar(resp?.data?.calendars?.selectedCalendarId)
                setCalendars(resp?.data?.calendars?.calendars.map(item => {
                    return {
                        value: item.id,
                        label: item.name
                    }
                }))
            }).catch(err => {
                console.error(err);
            });
    }

    function handleSubmitCalendar () {
        if (!chosenCalendar) return

        axios.post(`${BASE_URL}setConsultantCalendar?consultant_id=${props.item.consultant_id}`, {
            calendarId: chosenCalendar
        })
        .then(resp => {
            console.log('calendar successfully changed')
            setIsCalendarsOpen(false)
        }).catch(err => {
            console.error(err);
        });
    }

    const toAMPM = (string) => {
        let time = string.slice(0,2)
     
       
        if (parseInt(string.slice(0,2)) >12) {
            time = (parseInt(string.slice(0,2)) - 12) + string.slice(2,6) + ' PM'
        } else if (parseInt(string.slice(0,2)) === 12) {
            time = parseInt(string.slice(0,2)) + string.slice(2,6) + ' PM'
        } else {
            time = string.slice(0,2) + string.slice(2,6) + ' AM'
        }
        return time ;
    }

    const returnCalendarName = () => {
        let value 
        calendars.forEach(item => {
            if (item.value === chosenCalendar) value = item.label
        })
        return value
    }

    return (
        <Styled.Container>
            <Modal backdrop="static" role="alertdialog" open={open} onClose={handleClose} style={{ minHeight: 'auend' }}>
                <Modal.Body>
                    <form style={{ 'margin': '10px 10px', 'width': '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div style={{ display: 'flex', gap: '20px' }}>
                            <div style={{ width: '60px' }}>
                                <Checkbox defaultChecked={form.schedule?.sun?.available} onChange={(e, checked) => setForm({ ...form, schedule: { ...form?.schedule, sun: { ...form?.schedule?.sun, available: checked } } })}>{DAYS[0]}</Checkbox>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '35%' }}>
                                <SelectPicker 
                                    cleanable={false}
                                    defaultValue={form.schedule?.sun?.start}
                                    disabled={!form.schedule?.sun?.available} 
                                    searchable={false} data={selectTimes}
                                    onChange={e => {
                                        setSunTimes(selectTimes.filter((item) => Number(item.value.slice(0,2)) > Number(e.slice(0,2))));
                                        setRenderFlag((e) => !e);
                                        setForm({ ...form, schedule: { ...form?.schedule, sun: { ...form?.schedule?.sun, start: e }}})
                                        }
                                    }
                                />
                            </div>
                            <span style={{ marginendp: '8px' }}> - </span>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '35%' }}>
                                {renderFlag && <SelectPicker
                                                    style={{border: `${invalidFields[0] === 0 ? '' : '2px solid red'}`,borderRadius: `${invalidFields[0] === 0 ? '' : '8px'}`}}
                                                    cleanable={false}
                                                    defaultValue={form.schedule?.sun?.end}
                                                    disabled={!form.schedule?.sun?.available}
                                                    searchable={false} data={sunTimes}
                                                    onChange={e => setForm({ ...form, schedule: { ...form?.schedule, sun: { ...form?.schedule?.sun, end: e } } })} 
                                                />}
                                {!renderFlag && <SelectPicker
                                                    style={{border: `${invalidFields[0] === 0 ? '' : '2px solid red '}`,borderRadius: `${invalidFields[0] === 0 ? '' : '8px'}`}}
                                                    cleanable={false}
                                                    defaultValue={form.schedule?.sun?.end}
                                                    disabled={!form.schedule?.sun?.available}
                                                    searchable={false} data={sunTimes}
                                                    onChange={e => setForm({ ...form, schedule: { ...form?.schedule, sun: { ...form?.schedule?.sun, end: e } } })} 
                                                />}
                            </div>
                        </div>
                        <div style={{ display: 'flex', gap: '20px' }}>
                            <div style={{ width: '60px' }}>
                                <Checkbox defaultChecked={form.schedule?.mon?.available} onChange={(e, checked) => setForm({ ...form, schedule: { ...form?.schedule, mon: { ...form?.schedule?.mon, available: checked } } })}>{DAYS[1]}</Checkbox>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '35%' }}>
                            <SelectPicker 
                                    cleanable={false}
                                    defaultValue={form.schedule?.mon?.start}
                                    disabled={!form.schedule?.mon?.available} 
                                    searchable={false} data={selectTimes}
                                    onChange={e => {
                                        setMonTimes(selectTimes.filter((item) => Number(item.value.slice(0,2)) > Number(e.slice(0,2))));
                                        setRenderFlag((e) => !e);
                                        setForm({ ...form, schedule: { ...form?.schedule, mon: { ...form?.schedule?.mon, start: e }}})
                                        }
                                    }
                                />
                            </div>
                            <span style={{ marginendp: '8px' }}> - </span>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '35%' }}>
                                {renderFlag && <SelectPicker
                                                    style={{border: `${invalidFields[1] === 0 ? '' : '2px solid red'}`,borderRadius: `${invalidFields[1] === 0 ? '' : '8px'}`}}
                                                    cleanable={false}
                                                    defaultValue={form.schedule?.mon?.end}
                                                    disabled={!form.schedule?.mon?.available}
                                                    searchable={false} data={monTimes}
                                                    onChange={e => setForm({ ...form, schedule: { ...form?.schedule, mon: { ...form?.schedule?.mon, end: e } } })} 
                                                />}
                                {!renderFlag && <SelectPicker
                                                    style={{border: `${invalidFields[1] === 0 ? '' : '2px solid red '}`,borderRadius: `${invalidFields[1] === 0 ? '' : '8px'}`}}
                                                    cleanable={false}
                                                    defaultValue={form.schedule?.mon?.end}
                                                    disabled={!form.schedule?.mon?.available}
                                                    searchable={false} data={monTimes}
                                                    onChange={e => setForm({ ...form, schedule: { ...form?.schedule, mon: { ...form?.schedule?.mon, end: e } } })} 
                                                />}
                                </div>
                        </div>
                        <div style={{ display: 'flex', gap: '20px' }}>
                            <div style={{ width: '60px' }}>
                                <Checkbox defaultChecked={form.schedule?.tue?.available} onChange={(e, checked) => setForm({ ...form, schedule: { ...form?.schedule, tue: { ...form?.schedule?.tue, available: checked } } })}>{DAYS[2]}</Checkbox>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '35%' }}>
                                <SelectPicker 
                                    cleanable={false}
                                    defaultValue={form.schedule?.tue?.start}
                                    disabled={!form.schedule?.tue?.available} 
                                    searchable={false} data={selectTimes}
                                    onChange={e => {
                                        setTueTimes(selectTimes.filter((item) => Number(item.value.slice(0,2)) > Number(e.slice(0,2))));
                                        setRenderFlag((e) => !e);
                                        setForm({ ...form, schedule: { ...form?.schedule, tue: { ...form?.schedule?.tue, start: e }}})
                                        }
                                    }
                                />
                            </div>
                            <span style={{ marginendp: '8px' }}> - </span>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '35%' }}>
                                {renderFlag && <SelectPicker
                                                    style={{border: `${invalidFields[2] === 0 ? '' : '2px solid red'}`,borderRadius: `${invalidFields[2] === 0 ? '' : '8px'}`}}
                                                    cleanable={false}
                                                    defaultValue={form.schedule?.tue?.end}
                                                    disabled={!form.schedule?.tue?.available}
                                                    searchable={false} data={tueTimes}
                                                    onChange={e => setForm({ ...form, schedule: { ...form?.schedule, tue: { ...form?.schedule?.tue, end: e } } })} 
                                                />}
                                {!renderFlag && <SelectPicker
                                                    style={{border: `${invalidFields[2] === 0 ? '' : '2px solid red '}`,borderRadius: `${invalidFields[2] === 0 ? '' : '8px'}`}}
                                                    cleanable={false}
                                                    defaultValue={form.schedule?.tue?.end}
                                                    disabled={!form.schedule?.tue?.available}
                                                    searchable={false} data={tueTimes}
                                                    onChange={e => setForm({ ...form, schedule: { ...form?.schedule, tue: { ...form?.schedule?.tue, end: e } } })} 
                                                />}
                                </div>
                        </div>
                        <div style={{ display: 'flex', gap: '20px' }}>
                            <div style={{ width: '60px' }}>
                                <Checkbox defaultChecked={form.schedule?.wed?.available} onChange={(e, checked) => setForm({ ...form, schedule: { ...form?.schedule, wed: { ...form?.schedule?.wed, available: checked } } })}>{DAYS[3]}</Checkbox>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '35%' }}>
                            <SelectPicker 
                                    cleanable={false}
                                    defaultValue={form.schedule?.wed?.start}
                                    disabled={!form.schedule?.wed?.available} 
                                    searchable={false} data={selectTimes}
                                    onChange={e => {
                                        setWedTimes(selectTimes.filter((item) => Number(item.value.slice(0,2)) > Number(e.slice(0,2))));
                                        setRenderFlag((e) => !e);
                                        setForm({ ...form, schedule: { ...form?.schedule, wed: { ...form?.schedule?.wed, start: e }}})
                                        }
                                    }
                                />
                            </div>
                            <span style={{ marginendp: '8px' }}> - </span>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '35%' }}>
                                {renderFlag && <SelectPicker
                                                    style={{border: `${invalidFields[3] === 0 ? '' : '2px solid red'}`,borderRadius: `${invalidFields[3] === 0 ? '' : '8px'}`}}
                                                    cleanable={false}
                                                    defaultValue={form.schedule?.wed?.end}
                                                    disabled={!form.schedule?.wed?.available}
                                                    searchable={false} data={wedTimes}
                                                    onChange={e => setForm({ ...form, schedule: { ...form?.schedule, wed: { ...form?.schedule?.wed, end: e } } })} 
                                                />}
                                {!renderFlag && <SelectPicker
                                                    style={{border: `${invalidFields[3] === 0 ? '' : '2px solid red '}`,borderRadius: `${invalidFields[3] === 0 ? '' : '8px'}`}}
                                                    cleanable={false}
                                                    defaultValue={form.schedule?.wed?.end}
                                                    disabled={!form.schedule?.wed?.available}
                                                    searchable={false} data={wedTimes}
                                                    onChange={e => setForm({ ...form, schedule: { ...form?.schedule, wed: { ...form?.schedule?.wed, end: e } } })} 
                                                />}
                            </div>
                        </div>
                        <div style={{ display: 'flex', gap: '20px' }}>
                            <div style={{ width: '60px' }}>
                                <Checkbox defaultChecked={form.schedule?.thu?.available} onChange={(e, checked) => setForm({ ...form, schedule: { ...form?.schedule, thu: { ...form?.schedule?.thu, available: checked } } })}>{DAYS[4]}</Checkbox>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '35%' }}>
                            <SelectPicker 
                                    cleanable={false}
                                    defaultValue={form.schedule?.thu?.start}
                                    disabled={!form.schedule?.thu?.available} 
                                    searchable={false} data={selectTimes}
                                    onChange={e => {
                                        setThuTimes(selectTimes.filter((item) => Number(item.value.slice(0,2)) > Number(e.slice(0,2))));
                                        setRenderFlag((e) => !e);
                                        setForm({ ...form, schedule: { ...form?.schedule, thu: { ...form?.schedule?.thu, start: e }}})
                                        }
                                    }
                                />
                            </div>
                            <span style={{ marginendp: '8px' }}> - </span>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '35%' }}>
                                {renderFlag && <SelectPicker
                                                    style={{border: `${invalidFields[4] === 0 ? '' : '2px solid red'}`,borderRadius: `${invalidFields[4] === 0 ? '' : '8px'}`}}
                                                    cleanable={false}
                                                    defaultValue={form.schedule?.thu?.end}
                                                    disabled={!form.schedule?.thu?.available}
                                                    searchable={false} data={thuTimes}
                                                    onChange={e => setForm({ ...form, schedule: { ...form?.schedule, thu: { ...form?.schedule?.thu, end: e } } })} 
                                                />}
                                {!renderFlag && <SelectPicker
                                                    style={{border: `${invalidFields[4] === 0 ? '' : '2px solid red '}`,borderRadius: `${invalidFields[4] === 0 ? '' : '8px'}`}}
                                                    cleanable={false}
                                                    defaultValue={form.schedule?.thu?.end}
                                                    disabled={!form.schedule?.thu?.available}
                                                    searchable={false} data={thuTimes}
                                                    onChange={e => setForm({ ...form, schedule: { ...form?.schedule, thu: { ...form?.schedule?.thu, end: e } } })} 
                                                />}
                            </div>
                        </div>
                        <div style={{ display: 'flex', gap: '20px' }}>
                            <div style={{ width: '60px' }}>
                                <Checkbox defaultChecked={form.schedule?.fri?.available} onChange={(e, checked) => setForm({ ...form, schedule: { ...form?.schedule, fri: { ...form?.schedule?.fri, available: checked } } })}>{DAYS[5]}</Checkbox>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '35%' }}>
                            <SelectPicker 
                                    cleanable={false}
                                    defaultValue={form.schedule?.fri?.start}
                                    disabled={!form.schedule?.fri?.available} 
                                    searchable={false} data={selectTimes}
                                    onChange={e => {
                                        setFriTimes(selectTimes.filter((item) => Number(item.value.slice(0,2)) > Number(e.slice(0,2))));
                                        setRenderFlag((e) => !e);
                                        setForm({ ...form, schedule: { ...form?.schedule, fri: { ...form?.schedule?.fri, start: e }}})
                                        }
                                    }
                                />
                            </div>
                            <span style={{ marginendp: '8px' }}> - </span>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '35%' }}>
                                {renderFlag && <SelectPicker
                                                    style={{border: `${invalidFields[5] === 0 ? '' : '2px solid red'}`,borderRadius: `${invalidFields[5] === 0 ? '' : '8px'}`}}
                                                    cleanable={false}
                                                    defaultValue={form.schedule?.fri?.end}
                                                    disabled={!form.schedule?.fri?.available}
                                                    searchable={false} data={friTimes}
                                                    onChange={e => setForm({ ...form, schedule: { ...form?.schedule, fri: { ...form?.schedule?.fri, end: e } } })} 
                                                />}
                                {!renderFlag && <SelectPicker
                                                    style={{border: `${invalidFields[5] === 0 ? '' : '2px solid red '}`,borderRadius: `${invalidFields[5] === 0 ? '' : '8px'}`}}
                                                    cleanable={false}
                                                    defaultValue={form.schedule?.fri?.end}
                                                    disabled={!form.schedule?.fri?.available}
                                                    searchable={false} data={friTimes}
                                                    onChange={e => setForm({ ...form, schedule: { ...form?.schedule, fri: { ...form?.schedule?.fri, end: e } } })} 
                                                />}
                                </div>
                        </div>
                        <div style={{ display: 'flex', gap: '20px' }}>
                            <div style={{ width: '60px' }}>
                                <Checkbox defaultChecked={form.schedule?.sat?.available} onChange={(e, checked) => setForm({ ...form, schedule: { ...form?.schedule, sat: { ...form?.schedule?.sat, available: checked } } })}>{DAYS[6]}</Checkbox>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '35%' }}>
                            <SelectPicker 
                                    cleanable={false}
                                    defaultValue={form.schedule?.sat?.start}
                                    disabled={!form.schedule?.sat?.available} 
                                    searchable={false} data={selectTimes}
                                    onChange={e => {
                                        setSatTimes(selectTimes.filter((item) => Number(item.value.slice(0,2)) > Number(e.slice(0,2))));
                                        setRenderFlag((e) => !e);
                                        setForm({ ...form, schedule: { ...form?.schedule, sat: { ...form?.schedule?.sat, start: e }}})
                                        }
                                    }
                                />
                            </div>
                            <span style={{ marginendp: '8px' }}> - </span>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '35%' }}>
                                {renderFlag && <SelectPicker
                                                    style={{border: `${invalidFields[6] === 0 ? '' : '2px solid red'}`,borderRadius: `${invalidFields[6] === 0 ? '' : '8px'}`}}
                                                    cleanable={false}
                                                    defaultValue={form.schedule?.sat?.end}
                                                    disabled={!form.schedule?.sat?.available}
                                                    searchable={false} data={satTimes}
                                                    onChange={e => setForm({ ...form, schedule: { ...form?.schedule, sat: { ...form?.schedule?.sat, end: e } } })} 
                                                />}
                                {!renderFlag && <SelectPicker
                                                    style={{border: `${invalidFields[6] === 0 ? '' : '2px solid red '}`,borderRadius: `${invalidFields[6] === 0 ? '' : '8px'}`}}
                                                    cleanable={false}
                                                    defaultValue={form.schedule?.sat?.end}
                                                    disabled={!form.schedule?.sat?.available}
                                                    searchable={false} data={satTimes}
                                                    onChange={e => setForm({ ...form, schedule: { ...form?.schedule, sat: { ...form?.schedule?.sat, end: e } } })} 
                                                />}
                                </div>
                        </div>
                        <div className="mediation_checkbox">
                        <SelectPicker
                            style={{border: `${invalidFields[6] === 0 ? '' : '2px solid red '}`,borderRadius: `${invalidFields[6] === 0 ? '' : '8px'}`}}
                            cleanable={false}
                            defaultValue={form.mediationsPerDay}
                            searchable={false} data={mediationsPerDay}
                            onChange={e => {
                                console.log(e);
                                setForm({ ...form, mediationsPerDay: e })
                            }} 
                        />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleConfirm} appearance="primary">
                        Save
                    </Button>
                    <Button onClick={handleClose} appearance="subtle">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal backdrop="static" role="alertdialog" open={isCalendarsOpen} onClose={handleCLoseCalendarModal} style={{ minHeight: 'auend' }}>
                <Modal.Body>
                    <form style={{ 'margin': '10px 10px', 'width': '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div style={{ display: 'flex', gap: '20px' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                    width: '100%',
                                }}
                            >
                                <label>
                                    Choose calendar <span style={{ color: 'red' }}>*</span>
                                </label>
                                <SelectPicker 
                                    cleanable={false}
                                    value={chosenCalendar}
                                    searchable={false}
                                    data={calendars}
                                    onChange={(e) => {
                                       setChosenCalendar(e)
                                    }}
                                />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSubmitCalendar} appearance="primary">
                        Save
                    </Button>
                    <Button onClick={handleCLoseCalendarModal} appearance="subtle">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Styled.FieldContainer>
                <Styled.FieldLabel>Counsel:</Styled.FieldLabel>
                <Styled.FieldValue>{props.item.name}</Styled.FieldValue>
            </Styled.FieldContainer>
            {chosenCalendar && 
             <Styled.FieldContainer>
                <Styled.FieldLabel>Calendar:</Styled.FieldLabel>
                <Styled.FieldValue>{returnCalendarName()}</Styled.FieldValue>
            </Styled.FieldContainer>
            }
           
            <Styled.FieldTitle>Schedule</Styled.FieldTitle>
            <Styled.FieldSubContainer>
                <Styled.FieldSubLabel>{DAYS[0]}:</Styled.FieldSubLabel>
                <Styled.FieldValue>{props.item.schedule?.sun?.available ? `${toAMPM(props.item.schedule.sun.start.slice(0,5))} - ${toAMPM(props.item.schedule.sun.end.slice(0,5))}` : '-'}</Styled.FieldValue>
            </Styled.FieldSubContainer>
            <Styled.FieldSubContainer>
                <Styled.FieldSubLabel>{DAYS[1]}:</Styled.FieldSubLabel>
                <Styled.FieldValue>{props.item.schedule?.mon?.available ?`${toAMPM(props.item.schedule.mon.start.slice(0, 5))} - ${toAMPM(props.item.schedule.mon.end.slice(0, 5))}` : '-'}</Styled.FieldValue>
            </Styled.FieldSubContainer>
            <Styled.FieldSubContainer>
                <Styled.FieldSubLabel>{DAYS[2]}:</Styled.FieldSubLabel>
                <Styled.FieldValue>{props.item.schedule?.tue?.available ?`${toAMPM(props.item.schedule.tue.start.slice(0, 5))} - ${toAMPM(props.item.schedule.tue.end.slice(0, 5))}` : '-'}</Styled.FieldValue>
            </Styled.FieldSubContainer>
            <Styled.FieldSubContainer>
                <Styled.FieldSubLabel>{DAYS[3]}:</Styled.FieldSubLabel>
                <Styled.FieldValue>{props.item.schedule?.wed?.available ?`${toAMPM(props.item.schedule.wed.start.slice(0, 5))} - ${toAMPM(props.item.schedule.wed.end.slice(0, 5))}` : '-'}</Styled.FieldValue>
            </Styled.FieldSubContainer>
            <Styled.FieldSubContainer>
                <Styled.FieldSubLabel>{DAYS[4]}:</Styled.FieldSubLabel>
                <Styled.FieldValue>{props.item.schedule?.thu?.available ?`${toAMPM(props.item.schedule.thu.start.slice(0, 5))} - ${toAMPM(props.item.schedule.thu.end.slice(0, 5))}` : '-'}</Styled.FieldValue>
            </Styled.FieldSubContainer>
            <Styled.FieldSubContainer>
                <Styled.FieldSubLabel>{DAYS[5]}:</Styled.FieldSubLabel>
                <Styled.FieldValue>{props.item.schedule?.fri?.available ?`${toAMPM(props.item.schedule.fri.start.slice(0, 5))} - ${toAMPM(props.item.schedule.fri.end.slice(0, 5))}` : '-'}</Styled.FieldValue>
            </Styled.FieldSubContainer>
            <Styled.FieldSubContainer>
                <Styled.FieldSubLabel>{DAYS[6]}:</Styled.FieldSubLabel>
                <Styled.FieldValue>{props.item.schedule?.sat?.available ?`${toAMPM(props.item.schedule.sat.start.slice(0, 5))} - ${toAMPM(props.item.schedule.sat.end.slice(0, 5))}` : '-'}</Styled.FieldValue>
            </Styled.FieldSubContainer>

            <Button appearance="primary" onClick={() => setIsCalendarsOpen(true)}>Change calendar</Button>
            <Button appearance="primary" onClick={() => handleOpen()}>Edit</Button>

        </Styled.Container>


    )
}