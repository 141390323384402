import { styled } from "styled-components";

export const Container = styled.div`
    max-width: 600px;
    margin: 0 auto;
    padding-top: 100px;
`

export const Form = styled.form`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
`